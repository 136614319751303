import { createStore } from 'vuex';
import dashboard from './dashboard';
import auth from './auth';
import sidebar from './sidebar';
import role from './role';
import menu from './menu';
import submenu from './submenu';
import user from './user';
import profile from './profile';
import merk from './merk';
import kategori from './kategori';
import lokasi from './lokasi';
import satuan from './satuan';
import masterBarang from './masterBarang';
import stok from './stok';
import permintaanBarang from './permintaanBarang';
import persetujuanBarang from './persetujuanBarang';
import laporanTransaksi from './laporanTransaksi';
import tagihan from './tagihan';
import siswa from './siswa';
import values from './values';
import kelas from './kelas';
import mataPelajaran from './mataPelajaran';
import kelasGuru from './kelasGuru';
import aktivitas from './aktivitas';
import waliKelas from './waliKelas';
import izin from './izin';
import tahunPelajaran from './tahunPelajaran';
import dokumen from './dokumen';
import aktivitasStaff from './aktivitasStaff';
import aktivitasRegular from './aktivitasRegular';
import refleksi from './refleksi';
import pelanggaranSiswa from './pelanggaranSiswa';
import pushNotifikasi from './pushNotifikasi';

import surat from './surat';

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
  },
  actions: {},
  modules: {
    dashboard,
    auth,
    sidebar,
    role,
    menu,
    submenu,
    user,
    profile,
    surat,
    merk,
    kategori,
    lokasi,
    satuan,
    masterBarang,
    stok,
    permintaanBarang,
    persetujuanBarang,
    laporanTransaksi,
    tagihan,
    siswa,
    values,
    kelas,
    kelasGuru,
    aktivitas,
    waliKelas,
    mataPelajaran,
    izin,
    tahunPelajaran,
    dokumen,
    aktivitasStaff,
    aktivitasRegular,
    refleksi,
    pelanggaranSiswa,
    pushNotifikasi,
  },
});
